import "./src/base/styles/main.scss";

//gatsby-browser.js
import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";

export const wrapRootElement = ({ element }) => {
  return (
    <Auth0Provider
      domain={process.env.GATSBY_AUTH0_DOMAIN as string}
      clientId={process.env.GATSBY_AUTH0_CLIENTID as string}
      authorizationParams={{
        redirect_uri: process.env.GATSBY_AUTH0_CALLBACK,
      }}
    >
      {element}
    </Auth0Provider>
  );
};

import NotAuthorized from "@/components/sections/NotAuthorized";
import SimplifiedLayout from "@/components/layout/SimplifiedLayout";

export default function Unauthorized() {
  return (
    <SimplifiedLayout
      seo={{ title: "Page Not Found" }}
      language_code="nl"
      type="website"
    >
      <NotAuthorized />
    </SimplifiedLayout>
  );
}

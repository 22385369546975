// src/components/LoginButton.js
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "../Button";

function LoginButton({ children }) {
  const { loginWithPopup } = useAuth0();

  return (
    <Button variant="primary" onClick={() => loginWithPopup()}>
      {children}
    </Button>
  );
}

export default LoginButton;

import { useEffect } from "react";
import LoginButton from "@/components/elements/LoginButton";
import * as styles from "./styles.module.scss";

export function UnauthorizedBanner() {
  useEffect(() => {
    // Prevent scroll if this component is visible
    const htmlElement = document.getElementsByTagName("html")[0];
    htmlElement.style.overflowY = "hidden";

    return () => {
      htmlElement.style.overflowY = "scroll";
    };
  }, []);

  return (
    <div className={styles.whiteFade}>
      <div className={styles.banner}>
        <p className={styles.introduction}>
          Welkom op de health professional website van Alpro. Deze website is
          gemaakt voor health care professionals en bevat o.a. wetenschappelijke
          informatie. Om deze site te bezoeken zijn wij wettelijk verplicht u te
          laten registreren. Deze registratie is eenmalig en is uitsluitend
          bedoeld om informatie op de website te mogen raadplegen.
        </p>

        <LoginButton>Klik hier om te registreren</LoginButton>
      </div>
    </div>
  );
}

import React from "react";
import * as styles from "./styles.module.scss";

export function Loader() {
  return (
    <div className={styles.container}>
      <svg width={50} height={50} viewBox="0 0 50 50">
        <circle
          className={styles.path}
          cx="25"
          cy="25"
          r="20"
          fill="none"
          strokeWidth="5"
        />
      </svg>
    </div>
  );
}

export default Loader;

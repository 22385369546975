import React, { useEffect } from "react";
import { navigate } from "gatsby";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { withAuthenticationRequired } from "@auth0/auth0-react";

const Callback = () => {
  const { user } = useAuth0();

  useEffect(() => {
    (async () => {
      if (!user?.email) return;

      // "http://localhost:8888/.netlify/functions/cm-sign-up",
      const response = await axios.post(
        "/.netlify/functions/cm-sign-up",
        { email: user?.email },
        {
          headers: {
            "Content-Type": "application/json",
            "Content-Length": "999999999",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      console.log("response", response);

      navigate("/nl/");
    })();
  }, [user?.email]);

  return <>Redirecting...</>;
};

export default withAuthenticationRequired(Callback);

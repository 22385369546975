import { graphql, PageProps } from "gatsby";
import { lazy, useEffect, useRef, useState } from "react";
import { StoryblokResult } from "storyblok-js-client";

import { useInView } from "react-intersection-observer";
import { Storyblok } from "@/base/lib/storyblok";
import Heading from "@/components/elements/Heading";
import LinkComponent from "@/components/elements/LinkComponent";
import MainLayout from "@/components/layout/Main";
import ScientificUpdateCategoryList from "@/components/sections/ScientificUpdateCategoryList";
import HeaderScientificUpdate from "@/components/sections/ScientificUpdateList/HeaderScientificUpdate";

import * as styles from "@/components/sections/ScientificUpdateCategoryList/styles.module.scss";
import { LanguageContextProvider } from "@/base/context/LanguageContext";
import { getLocaleFromPathname } from "@/base/Language/getLocaleFromPathname";
import { getLayoutData } from "@/base/helpers/getLayoutData";
import { useProtectedRoute } from "@/base/hooks/useProtectedRoute";
import LazyIntersectionObserver from "../elements/LazyIntersectionObserver";
import ProfessionalsOnlyPopup from "../sections/ProfessionalsOnlyPopup";
import Loader from "../sections/Loader";
import UnauthorizedBanner from "../sections/UnauthorizedBanner";

type PageData = {
  headers: StoryblokNodes;
  footers: StoryblokNodes;
};

type PageContext = {
  category: CategoryType;
  categoryName: string;
};

function ScientificUpdateCategory({
  pageContext,
  data,
  location,
}: PageProps<PageData, PageContext>) {
  const locale = getLocaleFromPathname(location.pathname);
  const { isAuthenticated, isLoading } = useProtectedRoute(locale);

  const totalAmount = useRef(0);

  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const [_, handleIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [list, setList] = useState<
    (UpdatePageTemplate & { full_slug: string })[]
  >([]);

  const { category, categoryName } = pageContext;
  const { footers, headers } = data;

  const { header, footer } = getLayoutData(locale, headers, footers);

  const amountPerPage = process.env.GATSBY_SU_OFFSET
    ? parseInt(process.env.GATSBY_SU_OFFSET, 10)
    : 9;

  async function getSuByCategory() {
    handleIsLoading(true);

    const {
      data,
      total,
    }: Omit<StoryblokResult, "data"> & {
      data: { stories: ParsedStoryblokStory<UpdatePageTemplate>[] };
    } = await Storyblok.get(`cdn/stories`, {
      version: "published",
      filter_query: {
        component: { in: "template_scientificUpdate" },
        su_categories: {
          exists: category,
        },
      },
      page,
      cv: Date.now(),
      per_page: amountPerPage,
      sort_by: "content.su_date_of_publication:desc",
    });

    if (Array.isArray(data.stories)) {
      // TODO: check if this can filtered from api directly
      const filteredByLanguage = data.stories.filter((update) =>
        update.full_slug.startsWith(locale)
      );

      const newList = filteredByLanguage.map((el) => ({
        full_slug: `/${el.full_slug}`,
        ...el.content,
      }));
      setList([...list, ...newList]);
    }

    if (total && totalAmount.current !== total) {
      totalAmount.current = total;
    }

    handleIsLoading(false);
  }

  useEffect(() => {
    getSuByCategory();
  }, [page]);

  const seo = {
    title: `Update - ${categoryName}`,
    description: `Update - ${categoryName}`,
    url: `/updates/${categoryName}`,
  };

  // const LazyNewsletter = lazy(() => import("@/components/sections/Newsletter"))
  if (isLoading && locale === "nl") return <Loader />;

  return (
    <LanguageContextProvider language_code={locale}>
      <ProfessionalsOnlyPopup />

      <MainLayout header={header} footer={footer} seo={seo} type="website">
        {!isAuthenticated && <UnauthorizedBanner />}

        <HeaderScientificUpdate
          className={styles.categoryWrapper}
          setPage={(page: number) => setPage(page + 1)}
          dataSourceCategory="categories"
        >
          {/* <div className={styles.headerTextContainer}>
            <LinkComponent type="internal" url="/updates/">
              Scientific Updates /
            </LinkComponent>
            <Heading level={1}>Tag: “{categoryName}”</Heading>
          </div> */}
        </HeaderScientificUpdate>

        <ScientificUpdateCategoryList
          list={list}
          categoryId={category}
          setPage={setPage}
          page={page}
          amountPerPage={amountPerPage}
          totalAmount={totalAmount}
          tag="updates"
        />

        {/* <LazyIntersectionObserver
          Component={LazyNewsletter}
          inView={inView}
          ref={ref}
        /> */}
      </MainLayout>
    </LanguageContextProvider>
  );
}

export const pageQuery = graphql`
  query SuCategoryPageQuery {
    headers: allStoryblokEntry(
      filter: { field_component: { eq: "template_header" } }
    ) {
      nodes {
        full_slug
        content
      }
    }
    footers: allStoryblokEntry(
      filter: { field_component: { eq: "template_footer" } }
    ) {
      nodes {
        full_slug
        content
      }
    }
  }
`;

export default ScientificUpdateCategory;

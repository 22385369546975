import { graphql, PageProps } from "gatsby";
import { useRef, useState } from "react";

import { uppercaseFirstLetter } from "@/base/helpers/uppercaseFirstLetter";
import MainLayout from "@/components/layout/Main";
import ScientificUpdateCategoryList from "@/components/sections/ScientificUpdateCategoryList";
import * as styles from "@/components/sections/ScientificUpdateCategoryList/styles.module.scss";
import HeaderScientificUpdate from "@/components/sections/ScientificUpdateList/HeaderScientificUpdate";
import {
  LanguageContextProvider,
  useLanguageContext,
} from "@/base/context/LanguageContext";
import { getLocaleFromPathname } from "@/base/Language/getLocaleFromPathname";
import { getLayoutData } from "@/base/helpers/getLayoutData";
import { useProtectedRoute } from "@/base/hooks/useProtectedRoute";
import ResourceList from "../sections/Resources/ResourceList";
import ProfessionalsOnlyPopup from "../sections/ProfessionalsOnlyPopup";
import Loader from "../sections/Loader";
import UnauthorizedBanner from "../sections/UnauthorizedBanner";

type TCategories = {
  edges: {
    node: {
      id: string;
      name: string;
      content: string;
    };
  }[];
};

type PageData = {
  headers: StoryblokNodes;
  footers: StoryblokNodes;
  categories: TCategories;
};

type PageContext = {
  category: CategoryType;
  categoryName: string;
  dataSourceName: "resources";
};

function ResourcesCategory({
  pageContext,
  data,
  location,
}: PageProps<PageData, PageContext>) {
  const totalAmount = useRef(0);
  const locale = getLocaleFromPathname(location.pathname);
  const { isAuthenticated, isLoading } = useProtectedRoute(locale);

  const [page, setPage] = useState(1);

  const { category, categoryName, dataSourceName } = pageContext;
  const { footers, headers, resources } = data;

  const { header, footer } = getLayoutData(locale, headers, footers);

  const resourcesByLanguage = resources?.nodes?.filter(
    (node) => node.full_slug?.split("/")?.[0] === locale
  );

  const resourcesAll = resourcesByLanguage?.map((node) =>
    JSON.parse(node.content)
  );

  const resourcesByCategory = resourcesAll.reduce((acc, resource) => {
    const category = resource.category;

    acc[category] = [...(acc[category] || []), resource];

    return acc;
  }, {});

  const resourcesCurrent = resourcesByCategory[category];

  const amountPerPage = process.env.GATSBY_SU_OFFSET
    ? parseInt(process.env.GATSBY_SU_OFFSET, 10)
    : 9;

  const seo = {
    title: `${uppercaseFirstLetter(dataSourceName)} - ${categoryName}`,
    description: `${uppercaseFirstLetter(dataSourceName)} - ${categoryName}`,
    url: `/${dataSourceName}/${categoryName}`,
  };

  if (isLoading && locale === "nl") return <Loader />;

  return (
    <LanguageContextProvider language_code={locale}>
      <ProfessionalsOnlyPopup />

      <MainLayout header={header} footer={footer} seo={seo} type="website">
        {!isAuthenticated && <UnauthorizedBanner />}

        <HeaderScientificUpdate
          className={styles.categoryWrapper}
          setPage={(page: number) => setPage(page + 1)}
          dataSourceCategory={dataSourceName}
        >
          {/* <div className={styles.headerTextContainer}>
            <LinkComponent type="internal" url={ `/${ locale }/resources/` }>
              {dataSourceName.toUpperCase()} /
            </LinkComponent>
            <Heading level={1}>Tag: “{categoryName}”</Heading>
          </div> */}
        </HeaderScientificUpdate>

        <ScientificUpdateCategoryList
          categoryId={category}
          page={page}
          amountPerPage={amountPerPage}
          totalAmount={totalAmount}
          tag={dataSourceName}
        />

        <ResourceList
          categoryId={category}
          list={resourcesCurrent}
          isPaginationDisplayed={true}
        />
      </MainLayout>
    </LanguageContextProvider>
  );
}

export const pageQuery = graphql`
  query ResourcesCategoryPageQuery {
    headers: allStoryblokEntry(
      filter: { field_component: { eq: "template_header" } }
    ) {
      nodes {
        full_slug
        content
      }
    }
    footers: allStoryblokEntry(
      filter: { field_component: { eq: "template_footer" } }
    ) {
      nodes {
        full_slug
        content
      }
    }
    resources: allStoryblokEntry(
      filter: { field_component: { eq: "component_resource" } }
    ) {
      nodes {
        full_slug
        content
      }
    }
    categories: allStoryblokEntry(
      filter: { field_component: { eq: "template_page" } }
    ) {
      edges {
        node {
          id
          name
          content
        }
      }
    }
  }
`;

export default ResourcesCategory;
